import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useLoading } from "providers/loading";
import Controls from "../../../components/Controls";
import { Form, useForm } from "../../../hooks/useForm";
import { ICreateDataCycle } from "../../../models/Interfaces/IDataCycle";
import API from "../../../services/Api.Service";
import { API_URLS } from "../../../config/ApiUrls";
import { SuccessToast } from "../../../components/Toaster";
import routePaths from "../../../config/RoutePaths";
import "./Style.css";

let obj = {} as ICreateDataCycle;

export default function CreateDataCycle(this: any) {
  // Pull ID of data cycle from URL parameters
  // if no ID in parameters, it is a create data cycle screen
  const { id } = useParams();
  const { setAppLoading } = useLoading();
  const [organisations, setOrganisations] = useState([]);
  const [organisationSites, setOrganisationSites] = useState<any[]>([]);
  const [surveyTemplates, setSurveyTemplates] = useState<any[]>([]);
  const [dataCollectionMethod, setDataCollectionMethod] = useState<any[]>([]);
  const [languagesOptions, setLanguagesOptions] = useState<any[]>([]);
  const [dataCycleSeasons, setDataCycleSeasons] = useState<any[]>([]);
  const navigate = useNavigate();
  const initialFValues: ICreateDataCycle = {
    dataCycleName: "",
    organisationId: "",
    organisationIdTextSearch: "",
    organisationSiteId: "",
    organisationSiteIdTextSearch: "",
    surveyTemplateId: "",
    surveyTemplateIdTextSearch: "",
    languages: [],
    languageError: "",
    Workforcesize: "",
    startDate: null,
    endDate: null,
    dataCollectionMethods: [],
    dataCollectionError: "",
    dataCycleSeasonId: "",
    dataCycleSeasonIdTextSearch: "",
  };

  const pageHeading: string = id ? "Edit Survey  Cycle" : "Add Survey  Cycle";

  const validate = (fieldValues = values) => {
    if ("dataCycleName" in fieldValues)
      obj.dataCycleName = fieldValues.dataCycleName
        ? ""
        : "Survey cycle name is required.";

    if ("organisationSiteId" in fieldValues)
      obj.organisationSiteId = fieldValues.organisationSiteId
        ? ""
        : "Workplace is required.";

    if ("organisationId" in fieldValues)
      obj.organisationId = fieldValues.organisationId
        ? ""
        : "Business is required.";

    if ("surveyTemplateId" in fieldValues)
      obj.surveyTemplateId = fieldValues.surveyTemplateId
        ? ""
        : "Survey template is required.";

    if ("languages" in fieldValues)
      obj.languageError = fieldValues.languages.length
        ? ""
        : "Language(s) are required.";
    if ("Workforcesize" in fieldValues)
      obj.Workforcesize = fieldValues.Workforcesize
        ? ""
        : "Current workforce size is required.";
    if ("dataCollectionMethods" in fieldValues)
      obj.dataCollectionError = fieldValues.dataCollectionMethods.length
        ? ""
        : "Data sources is required.";
    if ("startDate" in fieldValues)
      obj.startDate = fieldValues.startDate
        ? ""
        : "Data cycle start date is required.";
    if ("endDate" in fieldValues)
      obj.endDate = fieldValues.endDate
        ? ""
        : "Data cycle end date is required.";
    if ("dataCycleSeasonId" in fieldValues)
      obj.dataCycleSeasonId = fieldValues.dataCycleSeasonId
        ? ""
        : "Survey cycle season is required.";

    setErrors({
      ...obj,
    });

    if (fieldValues === values)
      return Object.values(obj).every((x) => x === "");
  };

  const { values, setErrors, handleInputChange, setDataValues, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const url = id ? API_URLS.EditDataCycle : API_URLS.CreateDataCycle;
    if (validate()) {
      setAppLoading(true);
      API.post(url, {
        dataCycleId: id ?? "",
        DataCycleName: values.dataCycleName,
        OrganisationId: values.organisationId,
        OrganisationSiteId: values.organisationSiteId,
        SurveyTemplateId: values.surveyTemplateId,
        Languages: values.languages.map((x: any) => {
          return x.key;
        }),
        NumberOfWorker: values.Workforcesize,
        StartDate: values.startDate,
        EndDate: values.endDate,
        DataCollectionMethods: values.dataCollectionMethods.map((x: any) => {
          return x.key;
        }),
        dataCycleSeasonId: values.dataCycleSeasonId,
      })
        .then((res: any) => {
          // Inform the user the organisation was saved successfully
          SuccessToast(res?.data?.message);
          // Reset the form and error values
          obj = {} as ICreateDataCycle;
          resetForm();
          // Navigate to data cycles screen
          navigate(routePaths.DATACYCLES);
        })
        .finally(() => {
          setAppLoading(false);
        });
    }
  };

  const updateSites = (selectedObject: any) => {
    values.organisationSiteId = "";
    values.organisationSiteIdTextSearch = "";
    setDataValues(values);
    const objectId = selectedObject.key;
    let siteOptions: any[] = [];
    API.get(API_URLS.GetOrganisationSites, {
      params: {},
    }).then((response: any) => {
      siteOptions = response.data.data.map((x: any) => {
        return {
          key: x.organisationSiteId,
          label: x.organisationSiteName,
          organisationId: x.organisationId,
        };
      });

      let result = siteOptions;

      if (objectId !== "" && objectId !== undefined) {
        result = siteOptions.filter((x) => {
          return x.organisationId === objectId;
        });
      }

      setOrganisationSites(result);
    });
  };

  const updateOrganisation = (selectedObject: any) => {
    const organisationIds = selectedObject.key;
    const index = organisationSites.findIndex(
      (x: any) => x.key === organisationIds,
    );
    if (index !== -1) {
      const { organisationId } = organisationSites[index];
      const organisationIndex = organisations.findIndex(
        (x: any) => x.key === organisationId,
      );
      const { label } = organisations[organisationIndex];
      values.organisationId = organisationId;
      values.organisationIdTextSearch = label;
      validate({ organisationId });
    } else {
      values.organisationId = "";
      values.organisationIdTextSearch = "";
      validate({ organisationId: "" });
    }
    setDataValues(values);
  };

  const handleAutoCompleteChange = (selectedObject: any, fieldName: any) => {
    values[fieldName] = selectedObject ? selectedObject.key : "";
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);
    if (fieldName === "organisationId") updateSites(selectedObject);

    if (fieldName === "organisationSiteId") updateOrganisation(selectedObject);
    validate({ [fieldName]: selectedObject });
  };

  const handleAutoCompleteTextInputChange = (text: any, field: any) => {
    const fieldName = `${field}TextSearch`;
    values[fieldName] = text;
    setDataValues(values);
  };

  const handleMultipleSelectAutoCompleteChange = (
    selectedObject: any,
    fieldName: string,
  ) => {
    if (selectedObject.length === 0) {
      values[fieldName] = [];
    } else {
      values[fieldName] = selectedObject;
    }
    if (obj) (obj as any)[fieldName] = "";
    setDataValues(values);
    setErrors(obj);
    validate({ [fieldName]: selectedObject });
  };

  const handleDateChange = (date: any) => {
    values.startDate = date;
    setDataValues(values);
    validate({ startDate: date });
  };

  const handleEndDateChange = (date: any) => {
    values.endDate = date;
    setDataValues(values);
    validate({ endDate: date });
  };

  useEffect(() => {
    API.get(API_URLS.DataCollectionMethod).then((response: any) => {
      const Options = response.data.data.map((dataSources: any) => {
        return {
          key: dataSources.dataCollectionMethodId,
          label: dataSources.method,
        };
      });
      setDataCollectionMethod(Options);
    });

    API.get(API_URLS.GetOrganisations).then((response: any) => {
      const organisatinOptions = response.data.data.map((x: any) => {
        return { key: x.organisationId, label: x.organisationName };
      });
      setOrganisations(organisatinOptions);
    });

    API.get(API_URLS.GetSurveyTemplates).then((response: any) => {
      const surveyTemplateOptions = response.data.data.map((x: any) => {
        return { key: x.surveyTemplateId, label: x.surveyTemplateName };
      });
      setSurveyTemplates(surveyTemplateOptions);
    });

    API.get(API_URLS.GetDataCycleSeasons).then((response: any) => {
      const surveyCycleSeasonsOptions = response.data.data.map((x: any) => {
        return { key: x.dataCycleSeasonId, label: x.dataCycleSeasonLabel };
      });
      setDataCycleSeasons(surveyCycleSeasonsOptions);
    });

    API.get(API_URLS.GetLanguages, {
      params: {},
    }).then((response: any) => {
      const languageOptions = response.data.data.map((x: any) => {
        return { key: x.languageId, label: x.languageLabel };
      });
      setLanguagesOptions(languageOptions);
    });
    API.get(API_URLS.GetOrganisationSites, {
      params: {},
    }).then((response: any) => {
      const siteDropdown = response.data.data.map((x: any) => {
        return {
          key: x.organisationSiteId,
          label: x.organisationSiteName,
          organisationId: x.organisationId,
        };
      });
      setOrganisationSites(siteDropdown);
    });

    return () => {
      // Remove all the field errors and reset the form
      obj = {} as ICreateDataCycle;
      resetForm();
    };
  }, []);

  // This useEffect checks whether ID is present in parameters
  // if so the form is prepopulated with the details of the data cycle to be edited
  useEffect(() => {
    if (id) {
      setAppLoading(true);
      API.get(API_URLS.GetDataCycleById, {
        params: {
          dataCycleId: id,
        },
      })
        .then((response: any) => {
          const result = response?.data;
          values.dataCycleName = result.dataCycleName;
          values.organisationId = result.organisationId;
          values.organisationIdTextSearch = result.organisationName;
          values.organisationSiteId = result.organisationSiteId;
          values.organisationSiteIdTextSearch = result.organisationSiteName;
          values.surveyTemplateId = result.surveyTemplateId;
          values.surveyTemplateIdTextSearch = result.surveyTemplateName;
          values.Workforcesize = result.numberOfWorker;
          values.startDate = result.startDate;
          values.endDate = result.endDate;
          values.dataCycleSeasonId = result.dataCycleSeasonId;
          values.dataCycleSeasonIdTextSearch = result.dataCycleSeasonLabel;
          values.languages = result.languages.map((item: any) => {
            return { key: item.languageId, label: item.languageLabel };
          });
          values.dataCollectionMethods = result.dataCollectionMethods.map(
            (item: any) => {
              return { key: item.dataCollectionMethodId, label: item.method };
            },
          );
          setDataValues(values);
        })
        .finally(() => {
          setAppLoading(false);
        });
    } else resetForm();
  }, [id]);

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container-view">
        <Grid container>
          <Grid item xs={12} sm={2} md={2} />
          <Grid item xs={12} sm={8} md={8}>
            <Grid container>
              <Grid item xs={12} className="grid-custom-spacing">
                <div className="page-heading-container">
                  <Controls.BackButton />
                  <h2 className="page-heading">{pageHeading}</h2>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              >
                <Controls.Input
                  id="dataCycleName"
                  name="dataCycleName"
                  label="Survey cycle name*"
                  value={values.dataCycleName}
                  onChange={handleInputChange}
                  error={obj.dataCycleName}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              >
                <Controls.AutoComplete
                  id="organisationId"
                  name="organisationId"
                  label="Business*"
                  value={values.organisationId}
                  inputValue={values.organisationIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={organisations}
                  error={obj.organisationId}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              >
                <Controls.AutoComplete
                  id="organisationSiteId"
                  name="organisationSiteId"
                  label="Workplace*"
                  value={values.organisationSiteId}
                  inputValue={values.organisationSiteIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={organisationSites}
                  error={obj.organisationSiteId}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              >
                <Controls.AutoComplete
                  id="surveyTemplateId"
                  name="surveyTemplateId"
                  label="Survey template*"
                  value={values.surveyTemplateId}
                  inputValue={values.surveyTemplateIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  varient="standard"
                  options={surveyTemplates}
                  error={obj.surveyTemplateId}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              >
                <Controls.AutoComplete
                  id="dataCycleSeasonId"
                  name="dataCycleSeasonId"
                  label="Survey cycle season*"
                  value={values.dataCycleSeasonId}
                  inputValue={values.dataCycleSeasonIdTextSearch}
                  onChange={handleAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  varient="standard"
                  options={dataCycleSeasons}
                  error={obj.dataCycleSeasonId}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              >
                <Controls.AutoComplete
                  id="languages"
                  name="languages"
                  label="Language(s)*"
                  values={values.languages}
                  multiple={true}
                  onChange={handleMultipleSelectAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={languagesOptions}
                  error={obj.languageError}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              >
                <Controls.Input
                  id="Workforcesize"
                  name="Workforcesize"
                  type="number"
                  label="Current workforce size*"
                  value={values.Workforcesize}
                  onChange={handleInputChange}
                  error={obj.Workforcesize}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              >
                <Controls.AutoComplete
                  id="dataCollectionMethods"
                  name="dataCollectionMethods"
                  label="Data sources*"
                  values={values.dataCollectionMethods}
                  onChange={handleMultipleSelectAutoCompleteChange}
                  onInputChange={handleAutoCompleteTextInputChange}
                  options={dataCollectionMethod}
                  multiple={true}
                  varient="standard"
                  error={obj.dataCollectionError}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              >
                <Controls.DatePicker
                  id="startDate"
                  name="startDate"
                  variant="inline"
                  value={values.startDate}
                  inputVariant="standard"
                  label="Survey cycle start date*"
                  placeholder="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  orientation="landscape"
                  onChange={handleDateChange}
                  error={obj.startDate}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="grid-custom-spacing"
              >
                <Controls.DatePicker
                  id="endDate"
                  name="endDate"
                  variant="inline"
                  value={values.endDate}
                  inputVariant="standard"
                  label="Survey cycle end date*"
                  placeholder="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  orientation="landscape"
                  onChange={handleEndDateChange}
                  error={obj.endDate}
                />
              </Grid>
            </Grid>
            <div className="form-buttons custom-floatRight">
              <br />
              <Controls.Button
                className="button blue"
                type="submit"
                text="Save"
              />
              <Controls.Button
                text="Cancel"
                className="cancel-button"
                variant="outlined"
                onClick={() => {
                  navigate(routePaths.DATACYCLES);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Form>
  );
}

import { useEffect, useState } from "react";
import Controls from "components/Controls";
import { IDownloadModal } from "models/Interfaces/IReports";
import { useLoading } from "providers/loading";
import { API_URLS } from "config/ApiUrls";
import API from "services/Api.Service";
import "./Style.css";

const AllResultsDownloadModal = ({
  setModal,
  items,
  organisationId,
  clientId,
}: IDownloadModal) => {
  const { setAppLoading } = useLoading();
  const [options, setOptions] = useState<any>([]);
  const [consentGiven, setConsentGiven] = useState(false);
  const [surveyTemplateTypes, setSurveyTemplateTypes] = useState<any>([]);
  const [selectedSurveyTemplateTypeId, setSelectedSurveyTemplateTypeId] =
    useState("");
  const [surveyTemplateTypeName, setSurveyTemplateTypeName] = useState("");

  useEffect(() => {
    // setAppLoading(true);
    const fetchData = async () => {
      try {
        // Start all your GET requests asynchronously
        const getTypes = API.get(API_URLS.GetSurveyTemplateTypes, {
          params: {
            organisationId,
            userId: clientId != null ? clientId : "",
          },
        }).then((response: any) => {
          const templateTypeOptions = response.data.data.map((x: any) => {
            return {
              id: x.surveyTemplateTypeId,
              label: x.surveyTemplateTypeName,
              code: x.surveyTemplateTypeCode,
            };
          });
          setSurveyTemplateTypes(templateTypeOptions);
          // const currentTemplateType = templateTypeOptions[0];
          // if (currentTemplateType) {
          //   setSelectedSurveyTemplateTypeId(currentTemplateType.id);
          //   setSurveyTemplateTypeName(currentTemplateType.label);
          // }
        });

        // Wait for all requests to complete
        await Promise.all([getTypes]);
      } finally {
        // All requests completed, set isLoading to false
        // setAppLoading(false);
      }
    };

    fetchData();
    return () => {
      // storage.clearSites();
      // setAppLoading(false);
    };
  }, []);

  useEffect(() => {
    const optionArray = items.map((x: any) => {
      return x.dataCycleId;
    });

    setOptions(optionArray);
  }, [items]);

  const handleSelectSurveyTemplate = (selectedObject: any) => {
    setSelectedSurveyTemplateTypeId(selectedObject.id);
    setSurveyTemplateTypeName(selectedObject.label);
  };

  const handleSelectSurveyTemplateTextInputChange = (text: any) => {
    setSurveyTemplateTypeName(text);
  };

  const downloadData = () => {
    if (options.length > 0) {
      setAppLoading(true);
      API.post(API_URLS.BulkDownloadResults, {
        DataCycleIds: options,
      })
        .then((res: any) => {
          const mineType = res.headers["content-type"];
          let name = res.headers["content-disposition"].replace(
            "attachment; filename=",
            "",
          );
          name = name.substring(0, name.indexOf(".csv"));
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          const blob = new Blob([res.data], { type: mineType });
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .finally(() => {
          setAppLoading(false);
          setModal();
        });
    }
  };

  return (
    <div className="modal">
      <div onClick={setModal} className="overlay" />
      <div className="modal-content">
        <h2>Download latest results for all workplaces</h2>
        {!consentGiven ? (
          <div className="consent-checkbox">
            <Controls.CheckBox
              checked={consentGiven}
              label="Responses provided by survey participants should not be altered. By downloading this data you agree to using it ethically and in ways that contribute to improving the wellbeing of the participants. Please check the box to proceed."
              key="consent"
              value={consentGiven}
              onChange={() => setConsentGiven(!consentGiven)}
            />
          </div>
        ) : (
          <div className="callCycle-options">
            <Controls.AutoComplete
              id="surveyTemplateTypeId"
              name="surveyTemplateTypeId"
              label="Select a survey template type*"
              value={selectedSurveyTemplateTypeId}
              inputValue={surveyTemplateTypeName}
              onChange={handleSelectSurveyTemplate}
              onInputChange={handleSelectSurveyTemplateTextInputChange}
              options={surveyTemplateTypes}
            />
          </div>
        )}
        <div className="form-buttons custom-floatRight">
          <Controls.Button
            className="button blue"
            onClick={downloadData}
            text="Download"
          />
          <Controls.Button
            text="Cancel"
            className="cancel-button"
            variant="outlined"
            onClick={setModal}
          />
        </div>
      </div>
    </div>
  );
};

export default AllResultsDownloadModal;

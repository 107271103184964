import { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import "./Style.css";

function DownloadsBox({
  hideAndShowModal,
  hideAndShowAllResultsModal,
  hideAndShowBenchmarksModal,
  showBenchmarksButton,
}: any) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="mapSitesBox">
      <div
        className={`mapSitesBoxBanner ${
          expanded ? "mapSitesBoxBannerBorder" : ""
        }`}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ display: "inline-block" }}>Downloads</p>
        </div>
        <div className="mapSitesBoxIcons">
          <div
            className="contentExpansion"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <div className={expanded ? "hiddenIcon" : "displayedIcon"}>
              <FaPlus />
            </div>
            <div className={expanded ? "displayedIcon" : "hiddenIcon"}>
              <FaMinus />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mapSitesBoxContent ${expanded ? "visible" : "invisible"}`}
      >
        <div className="siteSelectMap">
          <div className="mapDownloadButton" onClick={hideAndShowModal}>
            <p>Download workplace summaries</p>
          </div>
          <div
            className="mapDownloadButton"
            onClick={hideAndShowAllResultsModal}
          >
            <p>Download latest results for all sites</p>
          </div>
          {showBenchmarksButton && (
            <div
              className="mapDownloadButton"
              onClick={hideAndShowBenchmarksModal}
            >
              <p>Download group averages</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DownloadsBox;

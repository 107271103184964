import { IHorizontalBarChart } from "models/Interfaces/ICharts";
import { colours } from "../Colours";
import "./Style.css";

function BarChart({ items }: IHorizontalBarChart) {
  return (
    <div className="barChartContainer">
      <div className="legend-row-lines">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="legend-row-line" />
        ))}
      </div>
      <div className="barChart">
        <div className="barChartYAxis skinnySegment">
          <div className="legend">
            <div className="legendTextBox">
              <p>100%</p>
            </div>
            <div className="legendTextBox">
              <p>75%</p>
            </div>
            <div className="legendTextBox">
              <p>50%</p>
            </div>
            <div className="legendTextBox">
              <p>25%</p>
            </div>
            <div className="legendTextBox">
              <p>0%</p>
            </div>
          </div>
        </div>
        {items.map((item: any) => {
          return (
            <div className="segment">
              <div className="barChartValues">
                {item.unreachable > 0 && (
                  <div
                    className="barChartValue barChartToolTip"
                    style={{
                      height: `${item.unreachablePerc}%`,
                      backgroundColor: colours[3],
                    }}
                  >
                    <span className="barChartToolTipText">{`${item.unreachable} (${item.unreachablePerc}%)`}</span>
                  </div>
                )}
                {item.optedOut > 0 && (
                  <div
                    className="barChartValue barChartToolTip"
                    style={{
                      height: `${item.optedOutPerc}%`,
                      backgroundColor: colours[2],
                    }}
                  >
                    <span className="barChartToolTipText">{`${item.optedOut} (${item.optedOutPerc}%)`}</span>
                  </div>
                )}
                {item.incomplete > 0 && (
                  <div
                    className="barChartValue barChartToolTip"
                    style={{
                      height: `${item.incompletePerc}%`,
                      backgroundColor: colours[1],
                    }}
                  >
                    <span className="barChartToolTipText">{`${item.incomplete} (${item.incompletePerc}%)`}</span>
                  </div>
                )}
                {item.complete > 0 && (
                  <div
                    className="barChartValue barChartToolTip"
                    style={{
                      height: `${item.completePerc}%`,
                      backgroundColor: colours[0],
                    }}
                  >
                    <span className="barChartToolTipText">{`${item.complete} (${item.completePerc}%)`}</span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="barChartcategories">
        <div className="skinnySegment" />
        {items.map((item: any) => {
          return (
            <div className="category segment">
              <p style={{ fontSize: "0.75em", paddingRight: "20%" }}>
                {item.surveyCycleName}
              </p>
              <p style={{ fontSize: "0.75em", paddingRight: "0%" }}>
                Workforce: <b>{item.totalWorkers}</b>
              </p>
              <p style={{ fontSize: "0.75em", paddingRight: "0%" }}>
                Start: <b>{item.startDate}</b>
                <br />
                End: <b>{item.endDate}</b>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BarChart;

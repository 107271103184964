import { IHorizontalBarChart } from "models/Interfaces/ICharts";
import "./Style.css";

function ProgressChart({ items }: IHorizontalBarChart) {
  return (
    <div className="barChartContainer">
      <div className="legend-row-lines">
        {[...Array(4)].map((_, i) => (
          <div key={i} className="legend-row-line" />
        ))}
      </div>
      <div className="barChart">
        <div className="barChartYAxis skinnySegment">
          <div className="legend">
            <div className="legendTextBox">
              <p>100%</p>
            </div>
            <div className="legendTextBox">
              <p>75%</p>
            </div>
            <div className="legendTextBox">
              <p>50%</p>
            </div>
            <div className="legendTextBox">
              <p>25%</p>
            </div>
            <div className="legendTextBox">
              <p>0%</p>
            </div>
          </div>
        </div>
        {items.map((item: any) => {
          return (
            <div className="segment">
              <div className="barChartValues">
                {item.dataCyclePercentages.positivePercentage > 0 && (
                  <div
                    className="barChartValue progressBarChartToolTip"
                    style={{
                      height: `${item.dataCyclePercentages.positivePercentage}%`,
                      backgroundColor: "var(--reports-positive)",
                    }}
                  >
                    <span className="progressBarChartToolTipText">{`${item.dataCyclePercentages.positivePercentage}%`}</span>
                  </div>
                )}
                {item.dataCyclePercentages.neutralPercentage > 0 && (
                  <div
                    className="barChartValue progressBarChartToolTip"
                    style={{
                      height: `${item.dataCyclePercentages.neutralPercentage}%`,
                      backgroundColor: "var(--reports-neutral)",
                    }}
                  >
                    <span className="progressBarChartToolTipText">{`${item.dataCyclePercentages.neutralPercentage}%`}</span>
                  </div>
                )}
                {item.dataCyclePercentages.negativePercentage > 0 && (
                  <div
                    className="barChartValue progressBarChartToolTip"
                    style={{
                      height: `${item.dataCyclePercentages.negativePercentage}%`,
                      backgroundColor: "var(--reports-negative)",
                    }}
                  >
                    <span className="progressBarChartToolTipText">{`${item.dataCyclePercentages.negativePercentage}%`}</span>
                  </div>
                )}
                {item.dataCyclePercentages.totalResponsesCount === 0 && (
                  <div
                    className="barChartValue"
                    style={{
                      height: `100%`,
                      backgroundColor: "var(--reports-grey)",
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="barChartcategories">
        <div className="skinnySegment" />
        {items.map((item: any) => {
          return (
            <div className="category segment">
              <p style={{ fontSize: "0.75em", paddingRight: "20%" }}>
                {item.dataCycleName}
              </p>
              <p style={{ fontSize: "0.75em", paddingRight: "0%" }}>
                Score:{" "}
                <b>{item.dataCycleRating > 0 ? item.dataCycleRating : "N/A"}</b>
              </p>
              <p style={{ fontSize: "0.75em", paddingRight: "0%" }}>
                Start: <b>{item.startDate}</b>
                <br />
                End: <b>{item.endDate}</b>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProgressChart;
